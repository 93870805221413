.edit-profile-dialog {
  &__app-bar {
    background-color: #f7f8f9 !important;
    color: black !important;
    text-align: center;
    &__title {
      flex: 1;
      font-weight: bold !important;
    }
  }

  &__grid {
    display: flex;
    align-items: center;
    justify-content: center;
    &__left {
      padding-top: 100px;
      &__dialog {
        &__input {
          margin: 12px 0 !important;
        }
        &__input-date {
          margin: 16px 0;
          padding: 16px 0;
          border-radius: 4px;
          width: 100%;
        }
      }
    }
    &__right {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      &__card {
        max-width: 350px;
        &__action-area {
          &__text {
            overflow-wrap: break-word;
            padding: 4px 0;
          }
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center !important;
          align-items: center;
          background-color: #fff;
          &__img {
            padding: 12px;
            width: 220px;
            height: 220px;
            object-fit: contain;
          }
          button {
            text-transform: capitalize;
            padding: 10px;
            font-size: 14px;
            display: flex;
            gap: 8px;
            border-radius: 5px;
          }
          .anonymous {
            background: rgb(0, 149, 246);
            color: #fff;
            &:hover {
              opacity: 0.7;
              background: rgb(0, 149, 246);
              color: #fff;
            }
          }
          .cancel_anonymous {
            border: red solid 1px;

            color: #7f1d1d;
            background: #fef2f2;
            &:hover {
              opacity: 0.7;
              border: red solid 1px;

              color: #7f1d1d;
              background: #fef2f2;
            }
          }
        }
      }
    }
  }
}
