.feature-box-1 {
   padding: 32px;
   box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
   margin: 15px 0;
   position: relative;
   z-index: 1;
   border-radius: 10px;
   overflow: hidden;
   -moz-transition: ease all 0.35s;
   -o-transition: ease all 0.35s;
   -webkit-transition: ease all 0.35s;
   transition: ease all 0.35s;
   top: 0;
}
.feature-box-1 * {
   -moz-transition: ease all 0.35s;
   -o-transition: ease all 0.35s;
   -webkit-transition: ease all 0.35s;
   transition: ease all 0.35s;
}
.feature-box-1 .icon {
   width: 70px;
   height: 70px;
   line-height: 70px;
   background: #811315;
   color: #ffffff;
   text-align: center;
   border-radius: 50%;
   margin-bottom: 22px;
   font-size: 27px;
}
.feature-box-1 .icon i {
   line-height: 70px;
}
.feature-box-1 h5 {
   color: #20247b;
   font-weight: 600;
}
.feature-box-1 p {
   margin: 0;
}
.feature-box-1:after {
   content: '';
   position: absolute;
   top: 0;
   bottom: 0;
   left: auto;
   right: 0;
   border-radius: 10px;
   width: 0;
   background: #20247b;
   z-index: -1;
   -moz-transition: ease all 0.35s;
   -o-transition: ease all 0.35s;
   -webkit-transition: ease all 0.35s;
   transition: ease all 0.35s;
}
.feature-box-1:hover {
   top: -5px;
}
.feature-box-1:hover h5 {
   color: #ffffff;
}
.feature-box-1:hover p {
   color: rgba(255, 255, 255, 0.8);
}
.feature-box-1:hover:after {
   width: 100%;
   height: 100%;
   border-radius: 10px;
   left: 0;
   right: auto;
}
.section {
   padding: 100px 0;
   position: relative;
}
.section-title {
   padding-bottom: 45px;
}
.section-title h2 {
   font-weight: 700;
   color: #20247b;
   font-size: 45px;
   margin: 0 0 15px;
   border-left: 5px solid #811315;
   padding-left: 15px;
}
