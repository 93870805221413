.partner_profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin: auto;
  background: #fff;
  border-radius: 12px;
  padding: 30px 50px;
  max-width: 950px;
  box-shadow: 0 8px 32px 0 rgba(22, 24, 26, 0.11);

  .box_infor_first {
    display: flex;
    gap: 50px;

    .avt_image {
      position: relative;
      margin-top: 20px;

      .backgroud_image {
        position: absolute;
        width: 300px;
        height: 400px;
        left: -30px;
        top: 20px;
        border-radius: 15px;
        z-index: 0;
        border: 2px #8e6e51 dashed;
      }

      img {
        width: 250px;
        height: 250px;
        min-width: 250px;
        min-height: 250px;
        object-fit: cover;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        background: #fafcfb;
      }

      .update_image {
        font-size: 35px;
        padding: 5px;
        cursor: pointer;
        position: absolute;
        background: gainsboro;
        border-radius: 50%;
        z-index: 2;
        top: 211px;
        right: 36px;
      }

      .online,
      .offline {
        position: absolute;
        z-index: 2;
        top: 211px;
        right: 36px;
        font-size: 35px;

        path {
          opacity: 1;
        }

        path:nth-child(2) {
          color: #fff;
        }
      }

      .online {
        color: rgb(49, 162, 76);
      }

      .offline {
        color: #7f1d1d;
      }
    }

    .info_user {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 30px 0px 0px;
      color: #811315;


      .about_me {
        position: relative;

        p:nth-child(1) {
          opacity: 0.15;
          font-family: 'Poppins', sans-serif;
          font-size: 2.5rem;
          text-transform: uppercase;
          line-height: 0.9;
          color: black;
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: black;
        }

        p:nth-child(2) {
          position: absolute;
          left: 0;
          z-index: 1;
          top: 20px;
          font-weight: 600;
        }
      }

      .bio_box {
        .box_name {
          .user_name {
            font-size: 24px;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
          }

          .bio {
            strong {
              color: #000 !important;
            }

            padding-top: 10px;
            color: rgb(131, 132, 133);
          }
        }
      }

      .grid_container {
        .MuiGrid-item {
          display: flex;
          gap: 10px;
          align-items: center;

          p:nth-child(2) {
            color: #000;
            font-size: 14px;
          }

          .content_hidden {
            color: #67635f;
            filter: blur(3px);
          }
        }
      }

      .action_box {
        display: flex;
        gap: 30px;

        button {
          padding: 10px;
          width: 150px;
          border-radius: 10px;
          text-transform: capitalize;
        }

        button:nth-child(1) {
          border: 1px solid var(--main-color);
          color: #000;
        }

        .accept_request {
          background: rgb(77, 182, 108);
          color: #000;
          display: flex;
          gap: 5px;
          align-items: center;

          svg {
            font-size: 18px;
          }
        }

        .friend_request {
          background: var(--main-color);
          color: #000;
        }

        .add_request {
          background: rgb(0, 149, 246);
          color: #000;
          display: flex;
          gap: 5px;
          align-items: center;

          svg {
            font-size: 18px;
          }
        }

        .cancel_request {
          border: red solid 1px;
          color: #7f1d1d;
          background: #fef2f2;
          display: flex;
          gap: 5px;
          align-items: center;

          svg {
            font-size: 18px;
          }
        }
      }

      .contact_infor {
        display: flex;
        gap: 60px;
        padding: 10px 0px 10px 30px;

        .contact_infor_post {
          display: flex;
          gap: 1px;
          align-items: center;

          p:nth-child(1) {
            color: black;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 1.4px;
            -webkit-text-stroke-color: black;
            font-family: 'Roboto Mono', monospace;
            font-size: 30px;
          }

          p:nth-child(2) {
            display: flex;
            flex-direction: column;
            color: black;
            font-family: 'Roboto Mono', monospace;
            font-weight: 500;
            font-size: 0.9em;
            text-transform: uppercase;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}