.recent-activities .item {
   padding: 0 15px;
   border-bottom: 1px solid #eee;
}

.recent-activities .item div[class*='col-'] {
   padding: 15px;
}

.recent-activities h5 {
   font-weight: 400;
   color: #333;
}

.recent-activities p {
   font-size: 0.75em;
   color: #999;
}

.recent-activities .icon {
   width: 35px;
   height: 35px;
   line-height: 35px;
   background: #f5f5f5;
   text-align: center;
   display: inline-block;
}

.recent-activities .date {
   font-size: 0.75em;
   color: #999;
   padding: 10px;
}

.recent-activities .date-holder {
   padding: 0 !important;
   border-right: 1px solid #eee;
}

.card-close {
   position: absolute;
   top: 15px;
   right: 15px;
}

.card-close .dropdown-toggle {
   color: #999;
   background: none;
   border: none;
}

.card-close .dropdown-toggle:after {
   display: none;
}

.card-close .dropdown-menu {
   border: none;
   min-width: auto;
   font-size: 0.9em;
   border-radius: 0;
   -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);
   box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);
}

.card-close .dropdown-menu a {
   color: #999 !important;
}

.card-close .dropdown-menu a:hover {
   background: #796aee;
   color: #fff !important;
}

.card-close .dropdown-menu a i {
   margin-right: 10px;
   -webkit-transition: none;
   transition: none;
}
