.container-hpt {
   box-shadow: 6px 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
   margin: 14px;
   border-radius: 12px;
}

.hospital-detail {
   background: #f7f7ff;
   margin-top: 20px;
}

.hpt-card {
   position: relative;
   display: flex;
   flex-direction: column;
   min-width: 0;
   word-wrap: break-word;
   background-color: #fff;
   background-clip: border-box;
   border: 0 solid transparent;
   border-radius: 0.25rem;
   margin-bottom: 1.5rem;
   box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
}
.me-2 {
   margin-right: 0.5rem !important;
}
