.dialog-common {
  .MuiPaper-root {
    width: 450px;
  }

  .dialog-title {
    display: flex;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid gainsboro;

    .icon_warning {
      color: #d79b22;
      font-size: 24px;
    }

    p {
      color: #000;
    }
  }

  .dialog-content {
    padding-top: 20px !important;
    font-size: 16px;
    padding-left: 30px !important;
  }

  .close-icon {
    position: absolute;
    right: 10px;
    top: 7px;

    svg {
      color: #4b4949;
    }
  }

  .dialog-action {
    button {
      color: #000;
      font-size: 12px;
    }

    button:hover {
      background: #fff;
      color: #811315;
    }
  }
}